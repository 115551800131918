// @flow
/* global window */
import React from 'react';

import type { Node, } from 'react';
import { useQuery, } from 'react-apollo';
import type { ListDataType, } from '../../flowTypes/ListDataType';
import ListDataGetter from './ListDataGetter';
import LIST_VARIABLES_QUERY from './queries/getListVariables.graphql';

type Props = {
  updateListDuplication: Function,
  updatePersonalList: Function,
  articleId: string,
  pageType: string,
  isPersonal?: boolean,
  isArticle?: boolean,
  listData: ListDataType,
  query: string,
  children: Function,
  updateList: Function,
  view: string,
};

ListView.defaultProps = {
  isPersonal: false,
  isArticle: false,
};

function ListView(props: Props): Node {
  const {
    listData,
    articleId,
    isArticle,
    pageType,
    isPersonal,
    query,
    children,
    view,
  } = props;
  const excludeHistory = Array.isArray(props.variables?.history) && props.variables?.history.length
    ? props.variables?.history.join(',') : undefined;

  const dataGetterProps = isPersonal
    ? {
      variables: {
        listId: listData.contentId,
        articleId,
        pageType: pageType?.toLowerCase() || (isArticle ? 'article' : 'homepage'),
        exclude: excludeHistory,
      },
      isPersonal,
    }
    : { query, variables: props.variables, };

  return (
    <ListDataGetter
      {...dataGetterProps}
      view={view}
      listData={listData}
      query={query}
    >
      {dataProps => children({
        history: dataProps.variables?.history,
        getHistory: dataProps.variables?.getHistory,
        ...dataProps,
      })
      }
    </ListDataGetter>
  );
}

type ListWrapperProps = {
  updateListDuplication: Function,
  variables?: {
    offset: number,
    history: ?(string[]),
    getHistory: ?(() => string[]),
  },
  listData: ListDataType,
  isPersonal?: boolean,
  query: string,
  queryPersonal?: ?string,
  children: Function,
  view: string,
};

ListWrapper.defaultProps = {
  queryPersonal: null,
  isPersonal: false,
  variables: null,
};

export default function ListWrapper({
  children,
  view,
  isPersonal,
  ...props
}: ListWrapperProps): Node {
  const { data: listVariables, } = useQuery(LIST_VARIABLES_QUERY);

  const pageType = listVariables?.pageType || '';
  const articleId = listVariables?.articleId || '';
  // Client/SSR RENDER
  if (props.listData && !isPersonal) {
    return (
      <ListDataGetter {...props}>
        {dataProps => children({
          view,
          history: props.variables?.history,
          getHistory: props.variables?.getHistory,
          ...dataProps,
        })}
      </ListDataGetter>
    );
  }

  return (
    <ListView
      {...props}
      isPersonal
      view={view}
      pageType={pageType}
      articleId={articleId}
      isArticle={!!articleId}
    >
      {children}
    </ListView>
  );
}
