// @flow
import type { ListBiActionType, } from '../flowTypes/ListBiActionType';

export default function action(
  index: number,
  data: Object,
  biAction?: ?ListBiActionType,
  actionCode?: number
): ?() => void {
  let callbacks = null;

  if (typeof biAction === 'function') {
    callbacks = [ biAction, ];
  }
  else if (Array.isArray(biAction)) {
    callbacks = biAction;
  }

  return biAction
    ? () => (callbacks ? callbacks.forEach(cb => cb({ index, data, actionCode, })) : undefined)
    : null;
}
