// @flow
import { useFela, } from 'react-fela';
import React, { type Node, } from 'react';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import Footer from '../../../TeaserFooter/TeaserFooter';
import Image from '../../../Image/Image';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import { useIsBot, } from '../../../../hooks/useIsBot';

// import useMedia from '../../../../hooks/useMedia';

BenderView.defaultProps = {
  isLazyloadImages: true,
  biAction: null,
  biImpression: null,
  gaAction: null,
  articleId: null,
};
export default function BenderView({
  list,
  isLazyloadImages,
  gaAction,
  biAction,
  biImpression,
  updatePersonalList,
  inView,
}: ListViewProps): Node {
  const { theme, css, } = useFela();
  const [ hasImpressionSent, setHasImpressionSent, ] = React.useState(null);
  const { isBot, } = useIsBot();

  const { items, title, } = list;
  const horizontalPadding = 2;
  React.useEffect(() => {
    inView && !hasImpressionSent && items.map((item, i) => {
      i < 6 && biImpression && typeof setBiImpression === 'function' && setBiImpression(i, item, biImpression)();
      setHasImpressionSent(true);
      return null;
    }
    );
  }, [ biImpression, hasImpressionSent, inView, items, ]);

  if (!items || !items.length) return null;

  function itemRenderer(item, i) {
    if (item && i < 6) return BenderItem(item, setBiAction(i, item, biAction), isLazyloadImages, i);
    return null;
  }

  return (
    <Section
      className={css({
        backgroundColor: theme.color('white'),
        display: 'grid',
        gridGap: '1rem 4rem',
        // marginTop: '8rem',
        padding: `0 ${horizontalPadding}rem 2rem`,
        width: '100%',
        extend: [
          theme.mq({ until: 's', display: isBot ? undefined : 'none', }),
          theme.mq({ until: 'l', }, { gridTemplateColumns: 'repeat(3, 1fr)', }),
          theme.mq({ from: 'l', until: 'xl', }, { gridTemplateColumns: 'repeat(4, 1fr)', }),
          theme.mq({ from: 'xl', }, { gridTemplateColumns: 'repeat(6, 1fr)', }),
        ],
      })}
      data-test="bender"
    >
      {title ? (
        <ListViewHeader
          isHorizontal
          biAction={biAction}
          miscStyles={{
            gridArea: '1/1/2/-1',
            paddingInlineStart: `${horizontalPadding}rem`,
            marginInlineEnd: `-${horizontalPadding}rem`,
            marginInlineStart: `-${horizontalPadding}rem`,
          }}
          {...list}
        />
      ) : null}
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </Section>
  );
}

function BenderItem(item: TeaserDataType, biAction, isLazyloadImages, i): Node {
  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      key={item.contentId}
      areasTemplate='"media" "content" "footer"'
      rowTemplate="auto 1fr auto"
      rowGap="1rem"
      miscStyles={{
        display: [
          ...(i > 2 ? [ { until: 'l', value: 'none', }, ] : []),
          ...(i > 3 ? [ { from: 'l', until: 'xl', value: 'none', }, ] : []),
        ],
      }}
    >
      <TeaserMedia data={item} onClick={biAction}>
        <Image
          image={image}
          imgOptions={{ transforms: { aspect: 'vertical', width: '500', }, }}
          lazyLoad={isLazyloadImages}
        />
      </TeaserMedia>
      <TeaserHeader {...item} onClick={biAction} isGridItem />
      <Footer data={item} showTime={false} showAuthor />
    </Teaser>
  );
}
