// @flow
import type { ListBiImpressionType, } from '../flowTypes/ListBiImpressionType';

export default function impression(
  index: number,
  data: Object,
  biImpression?: ?ListBiImpressionType,
  actionCode?: number
): () => void {
  let callbacks = null;

  if (typeof biImpression === 'function') {
    callbacks = [ biImpression, ];
  }
  else if (Array.isArray(biImpression)) {
    callbacks = biImpression;
  }

  return biImpression
    ? () => (callbacks ? callbacks.forEach(cb => cb({ index, data, })) : undefined)
    : () => {};
}
