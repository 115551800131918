// @flow

import { useQuery, } from 'react-apollo';
import { MAIN_CONTENT_ID_QUERY, } from '@haaretz/graphql';

import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import usePreview from './usePreview';
import useTagId from './useTagId';
import useAuthorId from './useAuthorId';

export default function useMainContentIdData() {
  const articleId = useArticleId();
  const tagId = useTagId();
  const authorId = useAuthorId();
  const { previewId, } = usePreview();
  const router = useRouter();
  const path = router.query.path;

  const id = articleId || authorId || tagId || previewId;

  const { data, loading, error, } = useQuery(MAIN_CONTENT_ID_QUERY, {
    fetchPolicy: 'cache-first',
    variables: id ? {
      id,
    } : { path, },
  });

  if (loading) return null;
  if (error) {
    console.error('Main content id error', error);
    return null;
  }

  if (!data) return null;

  const mainContentId = data?.Page?.contentId;

  return mainContentId;
}
