// @flow
/* global window */
import * as React from 'react';
import gql from 'graphql-tag';
import { BENDER_LIST_QUERY, } from '@haaretz/graphql';
import BenderView from './BenderView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper';


const BenderPersonalQuery: any = gql`
  query BenderPersonalQuery($id: ID!) {
    List(id: $id) {
      #dedupeReadingHistory
      #isLazyloadImages
      #title
      #preventRender {
      #  from
      #  until
      #}
      items {
        contentId
        #... on Teaser {
        #  ...TeaserForBender
        #}
      }
    }
  }
`;

type BenderWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
}

export default function BenderWrapper(props: BenderWrapperProps): React.Node {
  return (
    <ListWrapper {...props} query={BENDER_LIST_QUERY} view="Bender">
      {dataProps => <BenderView {...dataProps} />}
    </ListWrapper>
  );
}
